<h3>{{ getAlertMessage() }}</h3>
<mat-form-field class="w-100" floatLabel="always">
  <mat-label>Motivo de la descarga</mat-label>
  <mat-select [(ngModel)]="selectedReason" [disabled]="loading">
    <mat-option *ngFor="let reason of reasons | obj2arr" [value]="reason">
      <span>{{ reason }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field *ngIf="selectedReason === 'Imprimir manualmente'" class="w-100" floatLabel="always">
  <mat-label>Motivo de la descarga</mat-label>
  <mat-select [(ngModel)]="selectedManualReprintReason" [disabled]="loading">
    <mat-option *ngFor="let reason of manualReprintReasons | obj2arr" [value]="reason">
      <span>{{ reason }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>

<button class="w-100 flex-row items-center" (click)="submitDownload()" *ngIf="!loading" [disabled]="!isValidDownload()"
        mat-raised-button
        color="primary"
>Descargar
</button>
<div *ngIf="loading" class="flex flex-row items-center justify-center w-100">
  <mat-spinner diameter="40" color="primary"></mat-spinner>
</div>

