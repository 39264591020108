import { A2filter } from '@a2system/angular/common';
import { Filter } from '@a2system/angular/material';
import { Component, OnInit, Input, AfterViewInit, OnDestroy, ViewChild, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { cloneDeep, intersection, isEmpty } from 'lodash';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { Contains, Format, Order, OrderGroupPrintStatus, OrderProcessStatus, PageSize, PaperWeight, PrintColor } from 'src/app/models/models';
import { OrderService } from 'src/app/shared/order.service';
import { ImpresionContentFilterType, ImpresionService, PrinterStatusUI } from '../../impresion.service';
import { LogsDialogComponent } from '../logs-dialog/logs-dialog.component';
import { PendingOrdersTableDataSource } from './pending-orders-table-datasource';
import { take, tap, throttleTime } from 'rxjs/operators';
import { Printer, PrinterCapabilities } from 'src/app/models/printer-model';

export interface ActionEvent {
  type: 'print' | 'partialPrint' | 'loaded' | 'block' | 'delete' | 'reset',
  payload: any;
}
@Component({
  selector: 'app-pending-jobs-table',
  templateUrl: './pending-jobs-table.component.html',
  styleUrls: ['./pending-jobs-table.component.scss'],
})
export class PendingJobsTableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  // @Input() canPrint:boolean = true;
  @Input() filterContains = null;
  @Input() impresionContentFilterType: ImpresionContentFilterType = ImpresionContentFilterType.A4;
  @Input() selectedPageSize: PageSize = PageSize.A4;
  @Input() isMixedPrintActive: boolean = false;
  @Input() isLargerPageSizeActive: boolean = false;
  @Input() bnOnColorWarnPctByManufacturer: Map<Printer.Manufacturer, number>; // max bn/color ratio percentage: 30% of bn in a mix group, by manufacturer
  @Input() colorPrinterCapabilities: PrinterCapabilities;
  @Input() bnPrinterCapabilities: PrinterCapabilities;
  @Input() mixedPrinterCapabilities: PrinterCapabilities;
  @Output() action = new EventEmitter<ActionEvent>()
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<Order>;

  format: typeof Format = Format;
  OrderProcessStatus = OrderProcessStatus;
  bnOnColorWarnPctDefault: number = 100; // max bn/color ratio percentage: 30% of bn in a mix group

  filterForm = new FormGroup({ // BEWARE: rest of filter is hardcoded in datasource
    paymentStatus: new FormControl('paid'),
    // processStatus: new FormControl('pending'),
    // printableStatus:  new FormControl('pending'),
    contains: new FormControl([]),
  })
  // Contains = Contains;
  dataSource: PendingOrdersTableDataSource;
  updateTimeout;

  availablePageSizes: Array<PageSize> = [];

  a2filter = new A2filter(this.filterForm);
  orderChanged$;
  refreshSubscription: Subscription;
  displayedColumns = [];

  _refresh = new BehaviorSubject(null);
  refresh$ = this._refresh.asObservable();


  constructor(
    private orderService: OrderService,
    private impresionService: ImpresionService,
    public dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(`XXX =====>>>ngOnChanges:`);

    // console.log(`===================>>>>>> ngOnChanges changes.filterContains.currentValue: ${changes.filterContains.currentValue}`);
    // if (changes && changes.filterContains && !changes.filterContains.isFirstChange()) {
    if (changes && changes.filterContains) {
      const filter = isEmpty(changes.filterContains.currentValue) ? [] : [changes.filterContains.currentValue];
      this.filterForm.controls.contains.setValue(filter);
      this.a2filter.set('contains');
      // console.log(`==> 11111filterb: ${filter}`);
      // console.log(cloneDeep(this.a2filter));

      this.refresh();
    }
    // if (changes && changes.colorPrinterCapabilities) {
    //   console.log('ngOnChanges colorPrinterCapabilities', changes.colorPrinterCapabilities.currentValue);
    // }
    // if (changes && changes.bnPrinterCapabilities) {
    //   console.log('ngOnChanges bnPrinterCapabilities', changes.bnPrinterCapabilities.currentValue);
    // }
    // if (changes && changes.mixedPrinterCapabilities) {
    //   console.log('ngOnChanges mixedPrinterCapabilities', changes.mixedPrinterCapabilities.currentValue);
    // }

  }

  // {
  //   "key": "contains",
  //   "name": "Contenido",
  //   "type": 3, 
  //   "selected": {
  //       "id": "N",
  //       "label": "N - Impresión B/N únicamente",
  //       "value": [
  //           "N",
  //           "C",
  //           "M"
  //       ],
  //       "display": "Contenido: N - Impresión B/N únicamente, C - Impresión Color únicamente, M - Impresión Mixta",
  //       "op": "==="
  //   }
  // }

  ngOnInit(): void {
    // console.log(`=====>>> PendingJobsTable ON INIT: ${this.impresionContentFilterType}`);
    this.availablePageSizes = this.impresionContentFilterType == ImpresionContentFilterType.A4
      ? [PageSize.A4] : [PageSize.A4, PageSize.A5, PageSize.A3]; // force order
    this.displayedColumns = [].concat(
      'createdAt', 'priority', 'number', 'commentCount', 'printGroupCount', 'contains',
      this.availablePageSizes,
      'action',
    );

    document.title = `Copyfly producción ${this.impresionContentFilterType}`;

    this.a2filter.set('paymentStatus');
    // this.a2filter.set('processStatus');
    // this.a2filter.set('printableStatus');
    const filter = isEmpty(this.filterContains) ? [] : [this.filterContains];
    this.filterForm.controls.contains.setValue(filter);

    this.a2filter.set('contains');


    this.dataSource = new PendingOrdersTableDataSource(this.orderService, this.impresionService, this.impresionContentFilterType);
    this.orderChanged$ = this.orderService.onChange()
      .subscribe(data => {
        this.refresh();
      })

    this.refreshSubscription = this.refresh$.pipe(
      // tap(_ => console.log('A REFRESH')),
      throttleTime(1 * 1000, undefined, { leading: true, trailing: true }),
      // tap(_ => console.log('B REFRESH')),
    ).subscribe(_ => this.a2filter.refresh());
  }

  ngAfterViewInit() {
    // this.sort.sort(<MatSortable>({id: 'createdAt._seconds', start: 'asc'}));
    this.sort.sort(<MatSortable>({ id: 'priority', start: 'desc' }));
    this.dataSource.a2filter = this.a2filter;
    this.dataSource.sort = this.sort;
    // console.log("sort", this.dataSource.sort)
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    this.dataSource.loading$.subscribe(data => {
      if (!data) {
        this.action.emit({
          type: 'loaded',
          payload: { totalOrders: this.paginator.length }
        })
      }
    })
    setTimeout(() => {
      this.refresh();
    }, 0);
  }

  ngOnDestroy() {
    this.orderChanged$.unsubscribe();
    this.refreshSubscription?.unsubscribe();
  }
  // filterChange(filter: Filter) {
  //   console.log('===> filterChange XXXXX');
  //   if (filter.selected) {
  //     const op = filter.selected['op'] ?? '===';
  //     if (Array.isArray(filter.key)) {
  //       filter.key.forEach(k => {
  //         this.filterForm.controls[k].setValue(filter.selected.value[k]);
  //       })
  //     } else {
  //       if (filter.key === 'number') {
  //         // if ( ['priority', 'number'].includes(filter.key) ) {
  //         filter.selected.value = parseInt(filter.selected.value);
  //         filter.selected.value = filter.selected.value.toString();
  //       }
  //       this.filterForm.controls[filter.key].setValue(filter.selected.value);
  //     }
  //     this.a2filter.set(filter.key, op);
  //   } else {
  //     this.a2filter.unset(filter.key)
  //   }
  // }
  refresh() {
    this._refresh.next(null);
    // if (this.updateTimeout) clearTimeout(this.updateTimeout);
    // this.updateTimeout = setTimeout(() => {
    //   console.log('==> refresh', this.a2filter.filters.contains);
    //   this.a2filter.refresh();
    // }, 500)
  }

  onSelectMessage(row) {
    const dialogRef = this.dialog.open(LogsDialogComponent, {
      width: '50vw',
      maxHeight: '80vh',
      hasBackdrop: true,
      data: {
        row
      },
    });
  }

  onPrint(_order, type: 'black' | 'color' | null = null) {
    this.action.emit({
      type: 'print',
      payload: { order: _order, type }
    })
  }

  onPartialPrint(_order) {
    this.action.emit({
      type: 'partialPrint',
      payload: { order: _order }
    })

  }

  onBlock(_order, block: boolean = true) {
    this.action.emit({
      type: 'block',
      payload: { order: _order, block }
    })
  }

  onDelete(_order) {
    this.action.emit({
      type: 'delete',
      payload: { order: _order }
    })
  }

  onReset(_order) {
    this.action.emit({
      type: 'reset',
      payload: { order: _order }
    })
  }

  trackById(index: number, item: any) {
    return item.id
  }

  // isMixed = (order: Order) => order.contains?.includes('M'); // not used

  canPrintErrorMessage(_order: Order): string {
    // returns error message if this order can't be printed
    // otherwise, returns null

    // general print button active if all tests below pass:
    // make more efficient: other options are limited by UI
    const printers: Array<PrinterCapabilities> = [];

    // a) non-A4-only validations
    // (because they're kept in the list while printing different paper sizes, avoid danger to reprint an already printed group)
    const filteredGroups = _order.printStatusGroups?.filter(e => e.pageSize == this.selectedPageSize) ?? [];
    if (this.impresionContentFilterType !== ImpresionContentFilterType.A4) {
      // a.1) make sure all groups of this page size are pending. no page size in this order? can't print
      const cond = filteredGroups.length > 0 && filteredGroups.every(e => e.status == OrderGroupPrintStatus.PENDING);
      if (!cond) return `No hay impresión pendiente con folio ${this.selectedPageSize}`;
      // a.2) validate need of mixed print according to filteredGroups
      if (this.isMixedPrintActive) {
        const colors = new Set();
        filteredGroups.forEach(g => colors.add(g.color));
        if (colors.size == 1 && colors.has(PrintColor.BLACKNWHITE)) {
          return 'No se puede imprimir sólo negro por impresora mixta para el formato de papel seleccionado';
        }
      }
    }

    // b) check printer availability by color, add printers to be checked later
    if (_order.contains.includes('N')) {
      if (!this.bnPrinterCapabilities) {
        return 'No hay impresora de negro seleccionada';
      } else {
        printers.push(this.bnPrinterCapabilities);
      }
    } else if (_order.contains.includes('C')) {
      if (!this.colorPrinterCapabilities) {
        return 'No hay impresora a color seleccionada';
      } else {
        printers.push(this.colorPrinterCapabilities);
      }
    } else if (_order.contains.includes('M')) {
      if (this.isMixedPrintActive) {
        if (!this.mixedPrinterCapabilities) {
          return 'No hay impresora mixta seleccionada';
        } else {
          printers.push(this.mixedPrinterCapabilities);
        }
      } else {
        if (!this.bnPrinterCapabilities) {
          return 'No hay impresora de negro seleccionada';
        } else if (!this.colorPrinterCapabilities) {
          return 'No hay impresora a color seleccionada';
        } else {
          printers.push(this.bnPrinterCapabilities);
          printers.push(this.colorPrinterCapabilities);
        }
      }
    }

    // c1) stapled:
    if (_order.contains.includes('G')) {
      for (const printer of printers) {
        if (!printer.stapleSupported) {
          return `${printer.label} no soporta grapado`;
        }
      }
    }
    // c2) punch:
    if (intersection(['T2', 'T4', 'T'], _order.contains).length > 0) {
      for (const printer of printers) {
        if (!printer.punchSupported) {
          return `${printer.label} no soporta taladrado`;
        }
      }
    }
    // d) extra blank sheets:
    if (_order.contains.includes('BL')) {
      for (const printer of printers) {
        if (!printer.blankSheetsSupported) {
          return `${printer.label} no soporta folios blancos extras`;
        }
      }
    }

    // printer needs to be checked independently by color group for these cases below:
    // e,f) page size and paper weight, already filtered by selectedPageSize, remaining groups are color/weight pairs
    // g,h) print on larger page size, and coverLaminated
    for (const fg of filteredGroups) {
      // if (_order.number == 2060) {
      //   console.log(`#${_order.number}`, filteredGroups);
      // }
      const printer = printers.length == 1
        ? (printers[0]) // C, N or M
        : (fg.color == PrintColor.COLOR ? this.colorPrinterCapabilities : this.bnPrinterCapabilities);

      if (this.isLargerPageSizeActive) {
        if (!printer.largerPageSizeSupported.includes(fg.pageSize)) {
          return `${printer.label} no soporta imprimir ${fg.pageSize} en un formato superior`;
        }
      } else {
        if (!printer.pageSizeSupported.includes(fg.pageSize)) {
          return `${printer.label} no soporta folios ${fg.pageSize}`;
        }
      }
      if (!printer.paperWeightSupported.includes(fg.paperWeight)) {
        return `${printer.label} no soporta gramaje ${fg.paperWeight}g`;
      }
      // cover laminated by paper size filtered groups, colored only
      // not quite correct: we should filter by paper size, color AND coverLaminated in that group, but we don't have that info (we don't have coverLaminated by group)
      if (fg.color == PrintColor.COLOR) {
        if (_order.contains.includes('PPL') && !printer.coverLaminatedSupported) {
          if ([PageSize.A3, PageSize.A4].includes(fg.pageSize)) { // filter as much as possible... this is hardcoded! beware
            return `${printer.label} no soporta portadas plastificadas`;
          }
        }
      }
    }

    return null;
  }

  // canPrint(_order: Order, type: 'black' | 'color' | null = null): boolean { // now type is not used
  //   // general print button active if all groups of this size are pending
  //   // menu button active if color is pending
  //   // no page size in this order? can't print
  //   const color: PrintColor = type
  //     ? (type == 'black' ? PrintColor.BLACKNWHITE : PrintColor.COLOR)
  //     : null;
  //   const filteredGroups = _order.printStatusGroups?.filter(e => e.pageSize == this.selectedPageSize
  //     && (color ? e.color == color : true)) ?? [];
  //   const _canPrint = filteredGroups.length > 0
  //     && filteredGroups.every(e => e.status == OrderGroupPrintStatus.PENDING);
  //   return _canPrint;
  // }

  // returns: number > 0 ? has this % of warning, else: no warning
  hasMixedPrintWarningPct(order: Order & { bnToColorPct: number }): number {
    if (!this.isMixedPrintActive) {
      return 0;
    }
    const manufacturer = this.mixedPrinterCapabilities?.manufacturer;
    const pct = this.bnOnColorWarnPctByManufacturer?.[manufacturer] ?? this.bnOnColorWarnPctDefault;
    // console.log('hasMixedPrintWarningPct', manufacturer, order.bnToColorPct, pct, order.bnToColorPct > pct);
    return order.bnToColorPct > pct ? pct : 0;
  }

}
