import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderProcessStatus, OrderTypeStatus } from "../../models/models";
import { HttpClient } from "@angular/common/http";
import { ArchiveService } from "../../shared/archive.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { firestore } from "firebase";
import { cloneDeep } from "lodash";
import { UserService } from "../../core/user.service";

@Component({
  selector: 'app-document-download-request-modal',
  templateUrl: './document-download-request-modal.component.html',
  styleUrls: ['./document-download-request-modal.component.scss']
})
export class DocumentDownloadRequestModalComponent implements OnInit {

  reasons = [
    'Gestionar una incidencia',
    'Imprimir manualmente',
    'Consulta por parte de cliente',
  ];
  manualReprintReasons = [
    'Ajuste de página',
    'Autorrotación',
    'Caracteres extraños',
    'Error de comunicación con la impresora',
    'Error de procesamiento del documento',
    'Error PDF',
    'Falta de contenido',
    'Faltan documentos',
    'Otros',
  ];
  selectedReason = null;
  selectedManualReprintReason = null;
  isFile = null;
  archive = null;
  archives = null;
  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private dialogRef: MatDialogRef<DocumentDownloadRequestModalComponent>,
              private archiveService: ArchiveService, private db: AngularFirestore, private userService: UserService) {
  }

  ngOnInit(): void {
    this.isFile = !!this.data.file;
    if (!this.isFile) {
      if (this.data.printGroupId !== undefined && this.data.printGroupId !== null) {
        this.archiveService.getByOrderIdAndPrintGroupId(this.data.orderId, this.data.printGroupId).subscribe((archive) => {
          this.archive = archive;
        });
      } else {
        this.archiveService.getByOrderId(this.data.orderId).subscribe((archives) => {
          if (archives && typeof archives === 'object') {
            archives = Object.keys(archives).map(key => archives[key]);
          }
          this.archives = archives;
        });
      }
    }
  }

  getAlertMessage() {
    if (this.data.printGroupId) {
      return `Estás a punto de descargar el grupo ${this.data.printGroupId + 1}.`;
    } else {

    }
  }

  async submitDownload() {
    this.loading = true;
    let filesToDownload = [];
    let reason = '';

    if (!this.isFile && !this.archive) {
      if (this.data.printGroupsLength === undefined) {
        // Caso de un solo printGroup
        if (!this.archive) {
          const creationResult = await this.archiveService.createPrintGroupZip(this.data.orderId, [this.data.printGroupId]).toPromise();
          this.archive = creationResult.archives[0];
        }
        filesToDownload.push({
          ...this.archive.archiveFile,
          printGroup: this.archive.printGroupId + 1
        });
      } else {
        // Caso de múltiples printGroups - Verificar cuáles ya existen
        this.archives = this.archives || [];

        // Creamos un array con los índices de todos los printGroups posibles
        const allIndexes = Array.from({ length: this.data.printGroupsLength }, (_, i) => i);
        // Filtramos cuáles printGroups ya existen en this.archives
        const existingIndexes = this.archives.map(a => a.printGroupId);
        const missingIndexes = allIndexes.filter(i => !existingIndexes.includes(i));
        if (missingIndexes.length > 0) {
          // Solo generamos los archivos faltantes
          const creationResult = await this.archiveService.createPrintGroupZip(this.data.orderId, missingIndexes).toPromise();
          this.archives.push(...creationResult.archives);
        }

        // Ahora tenemos todos los archivos en this.archives
        filesToDownload = this.archives.map(a => {
          return {
            ...a.archiveFile,
            printGroup: a.printGroupId + 1
          };
        });
      }
    } else {
      // Caso de un solo archivo normal o un grupo
      if (this.data.file) {
        filesToDownload.push(this.data.file.originalFile);
      } else {
        filesToDownload.push({
          ...this.archive.archiveFile,
          printGroup: this.archive.printGroupId + 1
        });
      }
    }

    // Descargar todos los archivos en paralelo
    await Promise.all(filesToDownload.map(file => this.downloadFile(file.url, file.name)));
    reason = this.selectedReason + (!!this.selectedManualReprintReason ? ` (${this.selectedManualReprintReason})` : '');
    if (this.selectedManualReprintReason && this.selectedReason === 'Imprimir manualmente') {
      this.createManualReprintRequest(this.data.orderId, this.selectedManualReprintReason, filesToDownload);
    }
    this.dialogRef.close({ reason, downloadRequest: filesToDownload });
  }

  private downloadFile(fileUrl: string, fileName: string) {
    return new Promise((resolve, reject) => {
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe({
        next: (blob) => {
          // Crear un enlace temporal para descargar el archivo
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = fileName;

          // Simular clic y liberar memoria después de un pequeño retraso
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(() => window.URL.revokeObjectURL(url), 1000);

          resolve(true);
        },
        error: (err) => {
          console.error(`Error al descargar ${fileName}:`, err);
          reject(err);
        }
      });
    });
  }

  isValidDownload() {
    return !!this.selectedReason && (this.selectedReason !== 'Imprimir manualmente' || !!this.selectedManualReprintReason);
  }

  private async createManualReprintRequest(orderId, selectedManualReprintReason: any, filesToDownload) {
    filesToDownload = Array.isArray(filesToDownload) ? filesToDownload : [filesToDownload];
    const orderSnap = await this.db.collection('orders').doc(orderId).get().toPromise();

    filesToDownload.forEach(file => {
      const reprint = {
        order: {
          id: orderId,
          number: orderSnap.get('number'),
        },
        reason: selectedManualReprintReason,
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
        file: file.printGroup ? `Grupo ${file.printGroup}` : file.name,
        user: {
          id: this.userService.userG.id,
          displayName: this.userService.userG.displayName,
        },
      };
      console.log('reprint', cloneDeep(reprint));
      this.db.collection('manualReprintRequests').add(reprint);
    });
  }
}
